/* eslint-disable no-console */
console.log('Hello World! (from create-block-iquw-timeline block)');
/* eslint-enable no-console */

const height = document.querySelector('.wp-block-create-block-iquw-timeline .wp-block-column').offsetHeight

// const btn = document.querySelector('.btn')
const timeline = document.querySelector('.timeline')
timeline.style.maxHeight = `${height}px`

// btn.addEventListener('click', () => {
//   timeline.classList.toggle('animate')
// })

// intersection observer

const observer = new IntersectionObserver((entries) => {
  entries.forEach((entry, i) => {

    if (entry.boundingClientRect.top == 0) {
      console.log('out the top',)
    }

    if (entry.isIntersecting) {
      entry.target.classList.add('animate')
    } else {
      entry.target.classList.remove('animate')
    }

  });
});

const hiddenElements = document.querySelectorAll('.timeline');
hiddenElements.forEach((el) => observer.observe(el));
